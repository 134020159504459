











import { Component, Vue } from "vue-property-decorator";
import { MutationTypes } from "@/store/mutations";

@Component
export default class Banner extends Vue {
  refresh(): void {
    this.$router.go(0);

    navigator.serviceWorker.getRegistrations().then((regs) => {
      const reg = regs.find((reg) => {
        if (reg.active.scriptURL.indexOf("service-worker.js") != -1)
          return true;
      });
      reg.waiting.postMessage("skipWaiting");
    });
    this.$router.go(0);
    this.$store.commit(MutationTypes.SET_BANNER_STATE, false);
  }
}
