















import { Component, Vue } from "vue-property-decorator";
import LoginMainLayout from "@/layouts/default/LoginMainLayout.vue";
import AppBar from "@/components/defaultLayout/application/AppBar.vue";
import SignIn from "@/views/auth/SignIn.vue";
import NavigationMenuList from "@/components/defaultLayout/application/navigation/NavigationMenuList.vue";
import NavigationSetting from "@/components/defaultLayout/application/navigation/NavigationSetting.vue";

@Component({
  components: {
    LoginMainLayout,
    AppBar,
    SignIn,
    NavigationMenuList,
    NavigationSetting,
  },
})
export default class LoginIndex extends Vue {
  drawer = true;
}
