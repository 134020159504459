












import Vue from "vue";
import { Component } from "vue-property-decorator";
import NavigationListGroup from "@/components/defaultLayout/application/navigation/NavigationListGroup.vue";
import NavigationListItem from "@/components/defaultLayout/application/navigation/NavigationListItem.vue";

@Component({
  components: {
    NavigationListGroup,
    NavigationListItem,
  },
})
export default class NavigationMenuList extends Vue {
  get items() {
    const items = [
      { title: "Dashboard", icon: "mdi-view-dashboard", to: "/" },
      {
        title: this.$_haiiText("report"),
        icon: "mdi-chart-multiple",
        items: [
          { title: this.$_haiiText("alzguardReport"), to: "/alzguard/reports" },
        ],
      },
      {
        title: this.$_haiiText("userManagement"),
        icon: "mdi-account-multiple",
        to: "/users",
      },
    ];

    if (this.$store.getters.getAdmin.email.includes("haii.io")) {
      items.push({
        title: this.$_haiiText("serviceManagement"),
        icon: "mdi-file-cog-outline",
        items: [
          { title: this.$_haiiText("InstitutionList"), to: "/institutions" },
          {
            title: this.$_haiiText("gameGroupManagement"),
            to: "/game-group",
          },
        ],
      });
    }
    return items;
  }
}
