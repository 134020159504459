



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import NavigationListItem from "@/components/defaultLayout/application/navigation/NavigationListItem.vue";
import { ItemInterface } from "@/types/objectType";

@Component({
  components: {
    NavigationListItem,
  },
})
export default class NavigationListGroup extends Vue {
  @Prop() item!: ItemInterface;
}
