
































import { Component, Vue } from "vue-property-decorator";
import { cloudMessageInit } from "@/plugins/firebase/cloudMessaging";

@Component({
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class SignIn extends Vue {
  title = this.$_haiiName();

  mounted(): void {
    this.updateSignInUI();
  }

  updated(): void {
    this.updateSignInUI();
  }

  updateSignInUI(): void {
    cloudMessageInit();
    this.$_haii.auth.updateSignInUI(
      "#firebaseui-auth-container",
      this.$_haiiText("emailLoginMessage")
    );
  }

  async loginWithGoogle(): Promise<void> {
    cloudMessageInit();
    await this.$_haii.auth.googleLogin();
  }
}
