













import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ItemInterface } from "@/types/objectType";

@Component
export default class NavigationListItem extends Vue {
  @Prop() item!: ItemInterface;
}
