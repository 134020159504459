







import Vue from "vue";
import { Component } from "vue-property-decorator";
import Loading from "@/components/defaultLayout/util/Loading.vue";
import Banner from "@/components/defaultLayout/util/Banner.vue";
import { MutationTypes } from "@/store/mutations";

@Component({
  components: {
    Loading,
    Banner,
  },
})
export default class LoginMainLayout extends Vue {
  created() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (ev) => {
        if (ev.data === "bannerShow") {
          if (this.$store.state.setting.checkUpdateAlarm) {
            this.$store.commit(MutationTypes.SET_BANNER_STATE, true);
          }
        }
      });
    }
  }
}
