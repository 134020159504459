



























import { Component, Vue } from "vue-property-decorator";
import { ensure } from "@/lib/async";
import { ActionTypes } from "@/store/actions";

@Component
export default class NavigationSetting extends Vue {
  settings = [];
  lang = this.$store.getters.setting.locale;

  changeLang(value: string): void {
    const dispatchHook = (): void => {
      this.$vuetify.lang.current = value;
    };

    ensure(
      this.$store.dispatch(ActionTypes.LOCALE_SET, value).then(dispatchHook)
    );
  }
}
