













import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppBar extends Vue {
  changeDrawerState(): void {
    this.$emit("change");
  }

  signOut(): void {
    this.$_haii.auth.signOut();
    if (this.$route.path != "/") this.$router.push("/");
  }
}
